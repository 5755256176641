import * as yup from 'yup';
import { get, filter, isEmpty } from 'lodash-es';

yup.addMethod<yup.StringSchema>(yup.string, 'oneWord', function (message) {
  return this.test('oneWord', message, (value) => typeof value === 'string' && value.split(' ').length === 1);
});

yup.addMethod<yup.StringSchema>(yup.string, 'unicode', function (message) {
  return this.test('unicode', message, (value) => (value?.match(/^[\p{L}\s]*$/giu)?.length ?? -1) > 0);
});

yup.addMethod<yup.StringSchema>(yup.string, 'emailExtra', function (message) {
  return this.matches(/^[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/, message)
    .matches(/^(?!.*\.\.)(?!.*\.$)[^\s@]+@[^\s@]+\.[^\s@.]+$/, message)
    .test(
      'emailPrefix',
      message,
      (value) => ((value?.match(/[\w]{1}$/)?.length && value.split('@')[0]?.match(/[\w]{1}$/)?.length) ?? -1) > 0,
    )
    .test('emailLocal', message, (value) => (value?.split('@')[0]?.length ?? 0) <= 64)
    .test('emailPostfix', message, (value) => {
      return (value?.split('@')[1]?.match(/^[^.]/)?.length ?? -1) > 0;
    });
});

yup.addMethod<yup.StringSchema>(yup.string, 'noUnicode', function (message) {
  return this.test('noUnicode', message, (value) => (value?.match(/^[A-Z\s]*$/i)?.length ?? -1) > 0);
});

yup.addMethod<yup.StringSchema>(yup.string, 'noSpace', function (message) {
  return this.test('noSpace', message, (value) => (value?.match(/^[\S]+$/)?.length ?? -1) > 0);
});

yup.addMethod<yup.StringSchema>(yup.string, 'noNumber', function (message) {
  return this.test('noNumber', message, (value) => (value?.match(/\d/)?.length ?? 0) === 0);
});

yup.addMethod<yup.StringSchema>(yup.string, 'alphaNum', function (message) {
  return this.test('alphaNum', message, (value) => (value?.match(/^[A-Z0-9]*$/i)?.length ?? -1) > 0);
});

yup.addMethod<yup.StringSchema>(yup.string, 'alpha', function (message) {
  return this.test('alpha', message, (value) => (value?.match(/^[A-Z\s]*$/i)?.length ?? -1) > 0);
});

yup.addMethod<yup.StringSchema>(yup.string, 'numeric', function (message) {
  return this.test('numeric', message, (value) => (value?.match(/^[0-9]*$/)?.length ?? -1) > 0);
});

yup.addMethod<yup.StringSchema>(yup.string, 'noDoubleSpace', function (message) {
  return this.test('noDoubleSpace', message, (value) => (value?.match(/^(?!.* {2}).+$/)?.length ?? -1) > 0);
});

yup.addMethod(
  yup.array,
  'unique',
  // eslint-disable-next-line
  function (mapper = (a: any) => a, message: string = '${path} may not have duplicates') {
    return this.test('unique', message, (list: any) => {
      return list?.length === new Set(list?.map(mapper)).size;
    });
  },
);

// eslint-disable-next-line
yup.addMethod(yup.array, 'uniqueProperty', function (propertyPath, message = '${path} may not have duplicates') {
  return this.test('unique', '', function (list) {
    const errors: any[] = [];

    list?.forEach((item, index) => {
      const propertyValue = get(item, propertyPath);

      if (propertyValue && filter(list, [propertyPath, propertyValue]).length > 1) {
        errors.push(
          this.createError({
            path: `${this.path}[${index}].${propertyPath}`,
            message,
          }),
        );
      }
    });

    if (!isEmpty(errors)) {
      throw new yup.ValidationError(errors);
    }

    return true;
  });
});

declare module 'yup' {
  interface StringSchema<
    TType extends yup.Maybe<string> = string | undefined,
    TContext extends yup.AnyObject = yup.AnyObject,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    TDefault = undefined,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    TFlags extends yup.Flags = '',
    TOut extends TType = TType,
  > extends yup.Schema<TType, TContext, TOut> {
    oneWord(message: string): StringSchema<TType, TContext>;
    unicode(message: string): StringSchema<TType, TContext>;
    noUnicode(message: string): StringSchema<TType, TContext>;
    noSpace(message: string): StringSchema<TType, TContext>;
    noNumber(message: string): StringSchema<TType, TContext>;
    alphaNum(message: string): StringSchema<TType, TContext>;
    alpha(message: string): StringSchema<TType, TContext>;
    numeric(message: string): StringSchema<TType, TContext>;
    noDoubleSpace(message: string): StringSchema<TType, TContext>;
    emailExtra(message: string): StringSchema<TType, TContext>;
  }

  interface ArraySchema<
    TIn extends any[] | null | undefined,
    TContext,
    TDefault = undefined,
    TFlags extends yup.Flags = '',
  > extends yup.Schema<TIn, TContext, TDefault, TFlags> {
    unique(mapper: (a: TIn) => TIn, message?: any): ArraySchema<TIn | undefined, TContext, TDefault, TFlags>;
    uniqueProperty(id: string, message?: any): ArraySchema<TIn | undefined, TContext, TDefault, TFlags>;
  }

  // interface NumberSchema<
  //   TType extends yup.Maybe<number> = number | undefined,
  //   TContext extends yup.AnyObject = yup.AnyObject,
  //   TOut extends TType = TType,
  // > extends yup.Schema<TType, TContext, TOut> {
  //   emptyAsUndefined(): NumberSchema<TType, TContext>;
  // }
}

export default yup;
